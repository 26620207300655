import { combineReducers } from "redux";
import vehiclesReducer from "./vehicleReducer";
import labeledVehiclesReducer from "./labeledVehiclesReducer";
import vehicleListReducer from "./vehicleListReducer";
import settingsReducer from "./settingsReducer";
import faultCodesReducer from "./faultCodesReducer";
import alertPreferencesReducer from "./alertPreferencesReducer";

const rootReducer = combineReducers({
  vehicle: vehiclesReducer,
  labeledVehicles: labeledVehiclesReducer,
  vehicleList: vehicleListReducer,
  settings: settingsReducer,
  faultCodes: faultCodesReducer,
  alertPreferences: alertPreferencesReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
